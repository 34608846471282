import { container, Row, Col } from 'react-bootstrap';

const FormContainer = ({ children }) => {
  return (
    <container>
      <Row className="justify-constent-md-center">
        <Col xs={12} md={6}>
          {children}
        </Col>
      </Row>
    </container>
  );
};
export default FormContainer;
